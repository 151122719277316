<template>
  <div>

    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm de controle solar para janela</h2>

            <p>
              Resultado de constantes pesquisas e desenvolvimento tecnológico
              aplicados na produção de insulfilm de controle solar, as películas
              de Arquitetura para janelas reúnem características fundamentais
              para a perfeita climatização de ambientes, com ganhos expressivos
              em economia, privacidade e conforto.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Benefícios do insulfilm de proteção solar para janela</h3>

            <p>
              Os insulfilmes de controle solar para vidros de janelas tem melhor
              proteção contra os raios ultravioleta, radiação transmitida pelo
              sol, responsável pelo desbotamento de móveis, janelas, pinturas,
              painéis e decoração do ambiente. Os raios ultravioleta também
              podem causar danos significativos às pessoas, como envelhecimento
              precoce e câncer de pele.
            </p>

            <p>
              O insulfilm para vidro de janela pode bloquear
              até 99% dos raios UV, proteger o meio ambiente e aumentar a
              durabilidade de seus objetos. Além disso, os insulfilmes podem
              reduzir a incidência de energia solar em até 79%.
            </p>

            <p>
              Na prática, a aplicação do insulfilm torna o ambiente interno de
              residências, empresas, escritórios, lojas, mais agradáveis e
              proporciona mais conforto às pessoas. A luz solar direta pode
              causar fadiga e desatenção pela visão afetada. O insulfilm no
              vidro de janela, reduz a transmissão de luz e calor, tornando o
              ambiente mais confortável e eficiente.
            </p>

            <div @click="index = 1" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Insulfilmes Refletivos para janelas</h3>

            <p>
              É muito importante destacarmos bem a absorção e filtragem dos
              RAIOS ULTRAVIOLETAS, mais de 99% de filtragem.
            </p>

            <p>
              Os insulfilmes refletivos para vidros de janelas possuem dois
              tratamentos de filtragem dos raios UV, no adesivo de instalação e
              no adesivo de laminação.
            </p>

            <p>
              Deixa passar uma boa quantidade de LUZ VISÍVEL = 18% e que também
              filtra uma boa quantidade dos RAIOS INFRAVERMELHOS – CALOR = 79%.
              Os insulfilmes refletivos são fabricados pelo processo de
              bombardeamento iônico de partículas de metais – melhor e mais
              avançada tecnologia existente de metalização do poliéster (matéria
              prima básica da película).
            </p>

            <p>
              A metalização por bombardeamento iônico garante a máxima
              durabilidade ao insulfilm refletivo, e garante um alto desempenho
              e performance no bloqueio de energia solar, oferecendo um aspecto
              espelhado ao ambiente externo e protegendo contra os raios solares
              nocivos a nossa saúde.
            </p>

            <p>Cores: prata, vermelho, verde, bronze, dourado e outras.</p>
            
            <Table>
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">18%</td>
              </tr>
              <tr>
                <td>Reflexão de Energia Solar</td>
                <td class="text-right" width="20%">62%</td>
              </tr>
              <tr>
                <td>Transmissão de Raios Ultravioletas</td>
                <td class="text-right" width="20%">>1%</td>
              </tr>
              <tr>
                <td>Energia Total Refletida</td>
                <td class="text-right" width="20%">79%</td>
              </tr>
            </Table>            



            <!--             <div @click="index = 2" class="page-photo-container">
              <Photo
                :src="photos[2].thumb"
                :title="photos[2].title"
                :description="photos[2].description"
                :idLocation="photos[2].idLocation"
              />
            </div>

            <div @click="index = 3" class="page-photo-container">
              <Photo
                :src="photos[3].thumb"
                :title="photos[3].title"
                :description="photos[3].description"
                :idLocation="photos[3].idLocation"
              />
            </div> -->

            <h3>Insulfilmes transparentes para janelas</h3>

            <p>
              Em sua composição, o insulfilm de nano cerâmico para vidros de
              janelas evitam que as radiações solar sejam absorvidas, ou seja,
              os raios que transferem calor ficam retidos no material.
            </p>

            <div @click="index = 4" class="page-photo-container">
              <Photo
                :src="photos[4].thumb"
                :title="photos[4].title"
                :description="photos[4].description"
                :idLocation="photos[4].idLocation"
                :openLightBox="false"
              />
            </div>

            <p>
              Portanto, quando exposto ao sol por muito tempo, o vidro tende a
              gerar calor, o que prova sua eficiência em reter calor sem
              transferi-lo para o ambiente interno. Comparado com os insulfilmes
              comuns sem proteção infravermelha, o calor pode irradiar
              facilmente.
            </p>

            <p>
              O insulfilme transparente para janelas, irá proporcionar maior
              conforto térmico sem que altere a transparência das janelas,
              reduzindo a necessidade do uso intenso por exemplo do ar
              condicionado, pois diminui a temperatura interna sem que precise
              escurecer ou espelhar as janelas, proporcionando um melhor
              desempenho dos equipamentos de ar condicionado, gerando maior
              economia de energia.
            </p>

            <p>Cor: levemente azulado.</p>

            <Table>
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">70%</td>
              </tr>
              <tr>
                <td>Reflexão de Energia Solar</td>
                <td class="text-right" width="20%">9%</td>
              </tr>
              <tr>
                <td>Transmissão de Raios Ultravioletas</td>
                <td class="text-right" width="20%">>1%</td>
              </tr>
              <tr>
                <td>Energia Total Refletida</td>
                <td class="text-right" width="20%">51%</td>
              </tr>
            </Table>



            <h3>Insulfilmes G5 para janelas</h3>

            <p>
              Os insulfilmes para janelas do tipo fumê, popularmente conhecido
              como G5, tem como principal característica escurecer os vidros de
              janelas, criando um visual escuro, proporcionando grande redução
              de luminosidade e forte efeito de privacidade, com moderada
              rejeição de calor traz grande benefício por exemplo, para
              escritórios , empresas e locais onde a luz do sol incide em telas
              de computadores, dificultando a visualização.
            </p>

            <div @click="index = 5" class="page-photo-container">
              <Photo
                :src="photos[5].thumb"
                :title="photos[5].title"
                :description="photos[5].description"
                :idLocation="photos[5].idLocation"
                :openLightBox="false"
              />
            </div>

            <p>
              Considerando os fatores de proteção solares e redução de
              luminosidade, o insulfilme fume para janelas, podemos contar com
              algumas variações deste material, pois embora o nome insulfilm G5
              esteja associado a película fumê de tonalidade G5 clássica, também
              temos outros modelos com algumas variações de características,
              G20-G35-G50, quanto maior a graduação do G , maior será a
              transparência do insulfilme.
            </p>

            <p>
              A película mais recomendada para vidros de janelas é o insulfilme
              G5.
            </p>

            <p>Cor: Fume.</p>

            <Table>
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">5%</td>
              </tr>
              <tr>
                <td>Reflexão de Energia Solar</td>
                <td class="text-right" width="20%">5%</td>
              </tr>
              <tr>
                <td>Transmissão de Raios Ultravioletas</td>
                <td class="text-right" width="20%">>5%</td>
              </tr>
              <tr>
                <td>Energia Total Refletida</td>
                <td class="text-right" width="20%">41%</td>
              </tr>
            </Table>

            

<!--             <div @click="index = 6" class="page-photo-container">
              <Photo
                :src="photos[6].thumb"
                :title="photos[6].title"
                :description="photos[6].description"
                :idLocation="photos[6].idLocation"
                :openLightBox="false"
              />
            </div> -->

            <!-- Transmissão de Luz Visível............70% Reflexão de Energia Solar...............9.% Transmissão de Raios Ultravioletas..>1% Energia Total Refletida.....................51% Cor( levemente azulado) -->
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Table = () =>
  import(
    /* webpackChunkName: "Table" */ "@/components/shared/table/table.vue"
  );
  const Photo = () =>
  import(
    /* webpackChunkName: "Photo" */ "@/components/photo/photo.vue"
  );

export default {

  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
    Table,
  },

  data() {
    return {
      index: null,
      photos: [
        //refletivo
        {
          description:
            "Película prata instalada nas janelas da TAM para fornecer conforto térmico",
          idLocation: 1,
          src: "/images/fotos/tam/pelicula-janela-prata-tam-16",
          thumb: "/images/fotos/tam/small/pelicula-janela-prata-tam-16",
          title: "Película para Janela",
        },
        {
          description:
            "Película fumê invertido na C&C em Ribeirão Preto para reduzir o carlor e luminosidade",
          idLocation: 1,
          src: "/images/fotos/cec/pelicula-janela-fume-invertido-cec-1",
          thumb: "/images/fotos/cec/small/pelicula-janela-fume-invertido-cec-1",
          title: "Película para Janela",
        },
        {
          description:
            "Conforto térmico e diminuição dos reflexos em espelhos e monitores na Smart Fit Diadema",
          idLocation: 1,
          src: "/images/fotos/smartfit-diadema/pelicula-janela-smart-fit-diadema-51",
          thumb: "/images/fotos/smartfit-diadema/small/pelicula-janela-smart-fit-diadema-51",
          title: "Película para Janela",
        },
        {
          description:
            "Insulfilm azul refletivo para proteção solar e decoração das janelas da empresa",
          idLocation: 1,
          src: "/images/fotos/via-sao-paulo/pelicula-insulfilm-azul-via-sao-paulo-48",
          thumb: "/images/fotos/via-sao-paulo/small/pelicula-insulfilm-azul-via-sao-paulo-48",
          title: "Película para Janela",
        },

        //transparente
        {
          description:
            "Película transparente aplicada na Smart Fit - SP Market para proporcionar conforto térmico",
          idLocation: 1,
          src: "/images/fotos/smartfit-sp-market/pelicula-transparente-divisoria-smart-fit-sp-market-160",
          thumb: "/images/fotos/smartfit-sp-market/small/pelicula-transparente-divisoria-smart-fit-sp-market-160",
          title: "Película para Janela",
        },
        //fumes
        {
          description:
            "Filme G5 aplicado no SENAC para escurecer e proteger o ambiente dos raios solares",
          idLocation: 1,
          src: "/images/fotos/senac/pelicula-filme-g5-senac-44",
          thumb: "/images/fotos/senac/small/pelicula-filme-g5-senac-44",
          title: "Película para Janela",
        },
        {
          description:
            "Aplicada nas janelas do SESC Belenzinho para eliminar o reflexo do painel eletrônico interativo",
          idLocation: 1,
          src: "/images/fotos/sesc/pelicula-janela-sesc-belenzinho-108",
          thumb: "/images/fotos/sesc/small/pelicula-janela-sesc-belenzinho-108",
          title: "Película para Janela",
        },
      ],
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm de proteção solar para janela");
    this.$emit(
      `update:introduction`,
      "Aplicamos insulfilm de proteção solar para janela em comércios, sacadas, escritórios e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>
